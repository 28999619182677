<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
 
    <b-navbar-nav class="nav align-items-center ml-auto">
     <dark-Toggler right />
      <notification-dropdown  right/>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              test
            </p>
            <span class="user-status"></span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
               <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>

       
      </b-nav-item-dropdown>
    </b-navbar-nav>
   
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BModal,
  BForm,BFormInput,BFormGroup
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from "./NotificationDropdown.vue"
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,BModal,
    NotificationDropdown,BForm,BFormInput,BFormGroup,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data:function(){
    return{
          avatar:"",
      user:"user",
          nbusiness:0,
        rrevenue:0,
        revenueot:0,
        customerot:0,
        arpuot:0,
        mrcot:0,
        pipetgr:0,
        piperev:0,
        datos:true,
    }
  },
  computed:{
    /*name:function()
    {
      return this.user.name;
    },
    role: function(){
      if(this.user)
      {
        return this.user.rol;
      }
      return ""
        
    }*/
  },
  mounted(){
    // this.loadObjective();
  },
  methods:{
       logout: function() {
      setTimeout(() => {
        localStorage.removeItem("auth")
        this.$router.go({name:"login"});
        this.$root.auth = null;
      }, 10);
    }
  }
}
</script>
