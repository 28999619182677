//const user = JSON.parse(localStorage.getItem("auth"));
let arra=[
  {
        title: 'Home',
        route: 'home',
        icon: 'GridIcon'
      }
]
/*if(user.rol==="Manager")
{
  arra=[
    {
      title: 'Home',
      route: 'home',
      icon: 'GridIcon',
    },
   
  ]

}
else if(user.rol==="Sale")
{
  arra=[
    {
      header: 'Pages',
      icon: 'FileIcon',
      children: [
        {
          title: 'Home',
          route: 'home',
          icon: 'GridIcon',
        },{
          title: 'Notifications',
          route: 'email',
          icon: 'MailIcon',
        }
      ],
    },
  ]
}*/
export default arra
