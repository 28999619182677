
//const user = JSON.parse(localStorage.getItem("auth"));
let arra=[];
 arra=[
  {
    title: 'Home',
    route: 'home',
    icon: 'GridIcon',
  },
  /*
  {
    title: 'Blocks',
    route: 'blocks',
    icon: 'PackageIcon',
  },*/
  {
    title: 'Token Comparison',
    route: 'tokencomparison',
    icon: 'PackageIcon',
  },
  {
    title: 'NFT Analysis',
    route: 'floorprice',
    icon: 'PackageIcon',
  },
  {
    title: 'SQL Query',
    route: 'sqled',
    icon: 'PackageIcon',
  },
  {
    title: 'Dash Builder',
    route: 'dashb',
    icon: 'PackageIcon',
  },
  {
    title:'My Dashboards',
    route:'dashlist',
    icon:'PackageIcon'
  },
  {
    title:'Collection Analysis',
    route:'collectionanalysis',
    icon:'PackageIcon'
  }

]
/*if(user.rol==="Manager")
{
  arra=[
    {
      title: 'Home',
      route: 'home',
      icon: 'GridIcon',
    },
    {
      title: 'Team Scorecard',
      route: 'teamscorecard',
      icon: 'UsersIcon',
    },
    {
      title: 'REP Scorecard',
      route: 'repscorecard',
      icon: 'TrendingUpIcon',
    },
    {
      title: 'REP Activity',
      route: 'repactivity',
      icon: 'UserCheckIcon',
    },
    {
      title: 'Sales Team',
      icon:'ZapIcon',
      children:[
        {
          title: 'Sales Team',
          route: 'salesteam',
          icon: 'UsersIcon',
        },
        {
          title: 'Activity in Pipeline',
          route: 'salespipeline',
          icon: 'TrendingUpIcon',
        },
        {
          title: 'Sales Activity',
          route: 'annualrevenue',
          icon: 'UserCheckIcon',
        }
       
      ]
    },
    {
      title: 'Customers',
      route: 'customerinformation',
      icon: 'InfoIcon',
    },
    {
      title: 'Work Orders',
      route: 'scheduledworkorders',
      icon: 'TruckIcon',
    },
    {
      title: 'Todo',
      route: 'todo',
      icon: 'CheckSquareIcon',
    },
  
    {
      title: 'Calendar',
      route: 'hob-calendar',
      icon: 'CalendarIcon',
    },
    {
      title: 'Notifications',
      route: 'email',
      icon: 'MailIcon',
    }
  ]
  
}else if(user.rol==="Sale")
{
  arra=[
    {
      title: 'Home',
      route: 'home',
      icon: 'GridIcon',
    },
    {
      title: 'Work Orders',
      route: 'scheduledworkorderssale',
      icon: 'TruckIcon',
    },
    {
      title: 'Todo',
      route: 'todo',
      icon: 'CheckSquareIcon',
    },
    {
      title: 'Calendar',
      route: 'sale-calendar',
      icon: 'CalendarIcon',
    },
    {
      title: 'Activity in Pipeline',
      route: 'salespipelinesale',
      icon: 'TrendingUpIcon',
    },
    {
      title: 'Notifications',
      route: 'email',
      icon: 'MailIcon',
    }
  ]
  
}

else if(user.rol==="Admin")
{
  arra=[
    {
      title: 'Home',
      route: 'home',
      icon: 'GridIcon',
    }
  ]
  
}
*/
export default arra